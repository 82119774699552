import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import ionarrow from "../images/ionarrow.png";
import watering from "../images/usacsmileinv-min.png";
import opensvg from "../images/opensvg.png";


const About = () => {

  useEffect(() => {
    const handleImageLoad = (event) => {
      event.target.parentNode.classList.add("loaded");
    };

    const blurDivs = document.querySelectorAll(".blur-load");

    blurDivs.forEach(div => {
      const img = div.querySelector("img");
      if (img.complete) {
        div.classList.add("loaded");
      } else {
        img.addEventListener("load", handleImageLoad);
      }
    });

    return () => {
      blurDivs.forEach(div => {
        const img = div.querySelector("img");
        img.removeEventListener("load", handleImageLoad);
      });
    };
  }, []); 

    return (
      <div className="About">

        <div className="Container-column">
          <div className="about-title">
          A UX Designer with a passion for development.
          </div>

          <div className="about-text-img">
            <div className="ab-left">
              <div className="ab-bread">My mission is to translate the user-centric design into functional, code-based solutions. By merging design expertise with technical know-how, I aim to build captivating interfaces that seamlessly blend usability and aesthetics. </div>

            <div className="abo-div-mob blur-load">
              <img src={watering} alt="chad" className="abo-img mobionly loadedbild"></img>
              </div>

              <div className="ab-passions">
                <div className="pas-left">
                  <div className="pas-title">Design</div>
                  <div className="pas-item">UX/UI Design</div>
                  <div className="pas-item">UX Research</div>
                  <div className="pas-item">Usability Testing</div>
                  <div className="pas-item">Interaction design</div>
                  <div className="pas-item">Brand Design</div>
                </div>
                <div className="pas-right">
                  <div className="pas-title">Development</div>
                  <div className="pas-item">HTML/CSS/JS</div>
                  <div className="pas-item">Front End Development</div>
                  <div className="pas-item">JS-Ecosystem (React.Js, Svelte)</div>
                  <div className="pas-item">Headless CMS</div>
                  <div className="pas-item">Headless Ecommerce</div>

                  </div>

              </div>
            </div>
            <div className="ab-right">
              <div className="abo-div blur-load">
                <img src={watering} alt="chad" className="abo-img-desk loadedbild"></img>
              </div>
            </div>

          </div>
        </div>

        <div className="Container-column ab-b">
          <div className="Work-title ptop">Work <br></br>experience</div>

          <div className="threerow">
            <div className="ab-exp">
              <div className="exp-num e-border">03</div>
              
              <div className="exp-tags e-border">
                <div className="exp-tag exp-des">Designer</div>
                <div className="exp-tag exp-dev">Developer</div>
                <div className="exp-tag exp-co">Co-founder</div>
              </div>
              
              <NavLink to="/Laghmari" className="exp-title e-border">
                  Laghmari <img src={opensvg} alt="small window"></img>
                </NavLink>
              
              <div className="exp-date e-border">2023</div>
              
              <div className="exp-place e-border">Gothenburg / Stockholm</div>
              
              <div className="exp-url e-border">https://www.laghmari.com</div>
              
              <div className="exp-bread">Laghmari, a sustainability-focused clothing brand. I've contributed across garment design, marketing, web design, and Ecommerce development. Involved in every step, I've embraced a multidisciplinary approach to redefine fashion with a positive impact.</div>
            </div>

            <div className="ab-exp">
            <div className="exp-num e-border">02</div>
              
              <div className="exp-tags e-border">
                <div className="exp-tag exp-des">UX Designer</div>
                <div className="exp-tag exp-int">Internship</div>
              </div>
              
              <NavLink to="/OneStepBeyond" className="exp-title e-border">OneStepBeyond <img alt="small window" src={opensvg}></img></NavLink>
              
              <div className="exp-date e-border">2023</div>
              
              <div className="exp-place e-border">Gothenburg / Borås</div>
              
              <div className="exp-bread">Internship at a fast-paced strategic marketing agency as a UX Designer working with a vast variety of clients.</div>
            </div>

            <div className="ab-exp">
            <div className="exp-num e-border">01</div>
              
              <div className="exp-tags e-border">
                <div className="exp-tag exp-des">UX Designer</div>
                <div className="exp-tag exp-int">Internship</div>
              </div>
              
              <NavLink to="/Kryptoskatt" className="exp-title e-border">Kryptoskatt <img alt="small window" src={opensvg}></img></NavLink>
              
              <div className="exp-date e-border">2022</div>
              
              <div className="exp-place e-border">Gothenburg / India</div>
              
              <div className="exp-bread">Internship at a fintech startup that developed a financial services platform for users to import and manage their digital assets and cryptocurrencies.</div>
            </div>
          </div>

          <div className="see-all-projects ptop">
          <NavLink to="/Work" className="see-all-projects-button">
            <img src={ionarrow} className="ionarrow" alt=""></img>
            <div>See all projects</div>
            <div className="small-indicator">(5)</div>
          </NavLink>
        </div>
        </div>





      </div>
    );
  };
  
  export default About;