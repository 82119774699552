import React from "react";
import { NavLink } from "react-router-dom";

import flogo from "../images/footerlogo.svg";   

const Footer = () => {
    return (
        <footer>
            <div className="Container-column">

                <div className="love-to-hear">
                    <div className="love-text">I'd love to <br></br> hear from you!</div>
                    <a className="love-anch" href="mailto:mail@isacolanderux.com">mail@isacolanderux.com</a>
                </div>

                <div className="foot-site">
                    <div className="sitemap-links">
                        <div className="Foot-head">Sitemap</div>
                        <NavLink className="s-links button-effect-dark" to="/">Home</NavLink>
                        <NavLink className="s-links button-effect-dark" to="/Work">Work</NavLink>
                        <NavLink className="s-links button-effect-dark" to="/About">About</NavLink>
                        <NavLink className="s-links button-effect-dark" to="/Socials">Socials</NavLink>
                        
                    </div>
                    <div className="sitemap-socials">
                        <div className="Foot-head">Socials</div>
                        <a className="s-links button-effect-dark" href="https://www.linkedin.com/in/isacolander/">Linkedin</a>
                        <a className="s-links button-effect-dark" href="https://github.com/isacolander">Github</a>
                        <a className="s-links button-effect-dark" href="https://www.instagram.com/isac.olander/?hl=en">Instagram</a>
                    </div>
                </div>

                <div className="axiscontainer">
                    <div className="wordmarkfot">
                        <img src={flogo} alt="wordmark and pink star"></img>
                    </div>

                    <div className="col1">
                        <div className="thanks">
                            Thank you for being here!
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;