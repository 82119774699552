import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import ionarrow from "../images/ionarrow.png";
import isacstar from "../images/isacstar.png";
import smallosb from "../images/smallosb.png";
import regosb from "../images/regosb.png";
import smallkrypto from "../images/smallkrypto.png"
import regkrypto from "../images/regkrypto.png"
import lghmbox from "../images/lghm.png";
import osbgif from "../images/osbgif2.gif";
import kryptogif from "../images/kryptogif.gif";
import lghmgif from "../images/lghmgif.gif";

import researchgif from "../images/researchgif.gif";
import designgif from "../images/designgif.gif";
import devgif from "../images/devgif.gif";


const Home = () => {
  const [selectedService, setSelectedService] = useState("research");

  useEffect(() => {
    const handleImageLoad = (event) => {
      event.target.parentNode.classList.add("loaded");
    };

    const blurDivs = document.querySelectorAll(".blur-load");

    blurDivs.forEach(div => {
      const img = div.querySelector("img");
      if (img.complete) {
        div.classList.add("loaded");
      } else {
        img.addEventListener("load", handleImageLoad);
      }
    });

    return () => {
      blurDivs.forEach(div => {
        const img = div.querySelector("img");
        img.removeEventListener("load", handleImageLoad);
      });
    };
  }, []); 



  const handleServiceClick = (datatype) => {
    setSelectedService(datatype);
  };

  return (
    <div className="Home">

      <div className="Container-column stretchclass">

        <div className="Hero-text">
          <div className="line-wrapper starone">
            <div className="internal mover1">isac olander</div>
            <img className="internal mover2 isacstar" src={isacstar} alt="star of isac"></img>
          </div>
          <div className="line-wrapper">
            <div className="internal mover3">ux designer</div>
          </div>
        </div>

        <div className="herobread">
          I'm an ambitious, multidisciplinary UX Designer with a flair for development, dedicated to transforming creative ideas into reality through the fusion of design and code.
        </div>

        <NavLink to="/Work" className="herocta">
          <div className="Check-out">
          <img src={ionarrow} className="ionarrow" alt="small arrow"></img>
            <div>See all projects</div>
            <div className="small-indicator">(3)</div>
          </div>
          <div className="scrolltext">(Scroll)</div>
        </NavLink>
      </div>

      <div className="Container-column">

        {/* Work section */}
        <div className="Work-title">
          Work
        </div>

        <div className="lateralwrapper">
          <div className="lateral">
            {/* onestep */}
            <NavLink to="/OneStepBeyond" className="Container study osb">
              <img className="osbgif" src={osbgif} alt="onestepbeyond"></img>
              <img className="imgdefi smallosb" src={smallosb} alt="casestudy graphics"></img>
              <img className="imgdefi regosb" src={regosb} alt="casestudy graphics"></img>
            </NavLink>

            <div className="solvable">
              <div className="Case-info">
                <div className="Case-title">
                  Internship @ OneStepBeyond
                </div>

                <div className="Case-bread">
                  Designing experiences and conducting research at a fast-paced strategic marketing agency.
                </div>

                <NavLink to="/OneStepBeyond" className="lghm-cta fitwidt">
                  <img src={ionarrow} alt="small arrow"></img>
                  <div>Read about the testing!</div>
                </NavLink>
              </div>
            </div>
          </div>


          <div className="lateral">
            {/* Kryptoskatt */}
            <NavLink to="/Kryptoskatt" className="Container study kryptos">
              <img className="kryptogif" src={kryptogif} alt="kryptoskatt"></img>
              <img className="imgdefi smallosb" src={smallkrypto} alt="Me in italy"></img>
              <img className="imgdefi regosb" src={regkrypto} alt="casestudy graphics"></img>
            </NavLink>

            <div className="solvable">
              <div className="Case-info">
                <div className="Case-title">
                  Internship @ Kryptoskatt
                </div>

                <div className="Case-bread">
                  Designing and implementing interfaces and features for the alpha launch of a SaaS fintech startup.
                </div>

                <NavLink to="/Kryptoskatt" className="lghm-cta fitwidt">
                  <img src={ionarrow} alt="small arrow"></img>
                  <div>Read about intership</div>
                </NavLink>
              </div>
            </div>
          </div>

        </div>



        <div className="see-all-projects">
          <NavLink to="/Work" className="see-all-projects-button">
            <img src={ionarrow} className="ionarrow" alt="small arrow"></img>
            <div>See all projects</div>
            <div className="small-indicator">(3)</div>
          </NavLink>
        </div>

      </div>

      <div className="Container-column">
          <div className="lateralwrapper lwdiff">
          <div className="area-text">

            <div>(About me)</div>
            </div>
          <div className="areas-title">Bridging the gap between design and development</div>
          </div>
          
              <div className="areas-undertitel">
              Hey! I'm Isac Olander, a UX Designer from Gothenburg, Sweden.
              </div>
              <div className="areas-breadtext">
              I've always had a creative inclination, with a love for art, programming, and music. However, my journey into the world of UX design commenced during my time in IT support, where I developed a keen interest in enhancing the software, interfaces, and products I was troubleshooting, based on the insights and deep understanding I amassed. <br></br>
              <br></br>
              By sheer chance, I encountered a UX course offered by Google, and that's when I realized that my love for creativity, technology, and a profound desire to solve problems aligned perfectly with the world of UX design.
              </div>
            
          
      </div>

      <div className="Container-column lghmsection">

       <NavLink to="/Laghmari" className="laghmaribox blur-load">
        <img className="lghmgif" src={lghmgif} alt="laghmari"></img>
        <img src={lghmbox} className="loadedbild lghmbild" alt="marketing shoot" loading="lazy"></img>
       </NavLink>

       <div className="laghmaricontent">
        <div className="lghm-title">Laghmari: Design, Code, and Sustainability</div>
        <div className="lghm-bread">With 0% Fast-fashion and 100% Sustainability, Laghmari is a clothing brand with a mission to redefine the world of fashion. I've had the privilege of being deeply involved in every step of this exciting journey. From envisioning sustainable garment designs to crafting our digital presence, I've embraced a multidisciplinary approach to shape a brand that's not just about fashion, but about making a positive impact on the world.</div>
        <div className="lghm-tags">
                <div className="exp-tag exp-des">Designer</div>
                <div className="exp-tag exp-dev">Developer</div>
                <div className="exp-tag exp-co">Co-founder</div>
                </div>
        <NavLink to="/Laghmari" className="lghm-cta">
              <img src={ionarrow} alt="small arrow"></img>
              <div>About the project</div>
          </NavLink>
       </div>

      </div>

      <div className="Container-column mobileservices">
        <div className="services-title">
          (Services)
        </div>

        {/* Services section */}
        <div className="Work-title">
          Research
        </div>

        <div className="servicesbox resbg">
          <img className="gifboximgsmaller" src={researchgif} alt="research"></img>
        </div>

        <div className="Case-bread paddingbot">
        Immersed in exploring user behaviors and industry insights, my enthusiasm for statistics and data underpins thorough research. This commitment to understanding users and their needs forms the cornerstone for crafting user-centric strategies and innovative solutions.
        </div>

        <div className="divider"></div>

        {/* Services section */}
        <div className="Work-title ptop">
          Design
        </div>

        <div className="servicesbox desbg">
        <img className="gifboximgsmaller" src={designgif} alt="research"></img>
        </div>

        <div className="Case-bread paddingbot">
        Fusing a passion for coding and technical understanding with my design expertise, I craft intuitive, visually compelling experiences. My multidisciplinary approach ensures seamless design solutions that bridge technical finesse with engaging user interactions.
        </div>

        <div className="divider"></div>

        {/* Services section */}
        <div className="Work-title ptop">
          Development
        </div>

        <div className="servicesbox devbg">
          <img className="gifboximgsmaller" src={devgif} alt="research"></img>
        </div>

        <div className="Case-bread paddingbot">
        Driven by a deep interest in coding and building, my multidisciplinary skills contribute to the development process, turning concepts into robust, functional digital solutions. This intersection of design and technical finesse ensures the seamless execution of your digital aspirations
        </div>

        <div className="see-all-projects">
          <NavLink to="/Work" className="see-all-projects-button">
            <img src={ionarrow} className="ionarrow" alt="small arrow"></img>
            <div>See all projects</div>
            <div className="small-indicator">(3)</div>
          </NavLink>
        </div>

      </div>

      <div className="Container-column deskservices">
        <div className="services-title">
          (My areas)
        </div>
        <div className="services-lg">
          <div className="services-texts">
            <div
              className={`services-big ${selectedService === "research" ? "activebuh default-active" : ""}`}
              onClick={() => handleServiceClick("research")}
            >
              Research
            </div>
            <div
              className={`services-big ${selectedService === "design" ? "activebuh" : ""}`}
              onClick={() => handleServiceClick("design")}
            >
              Design
            </div>
            <div
              className={`services-big ${selectedService === "development" ? "activebuh" : ""}`}
              onClick={() => handleServiceClick("development")}
            >
              Development
            </div>
          </div>

          <div className="services-info">
            <div className="gifbox">
              {/* box 1 data 1 */}
              {selectedService === "research" && (
                <div className="gifboxtext" datatype="research">
                  <img className="gifboximg" src={researchgif} alt="research"></img>
                  </div>
              )}
                
              {/* box 2 data 2 */}
              {selectedService === "design" && (
                <div className="gifboxtext" datatype="design">
                  <img className="gifboximg" src={designgif} alt="research"></img>
                </div>
              )}

              {/* box 3 data 3 */}
              {selectedService === "development" && (
                <div className="gifboxtext" datatype="development">
                  <img className="gifboximg" src={devgif} alt="research"></img>
                </div>
              )}
                
            </div>
            <div className="services-data">
              {selectedService === "research" && (
                <div>
                  {/*data 1 */}
                  <div className="services-data-item" datatype="research">
                    <div className="internal mover1">UX Research</div>
                  </div>
                  <div className="services-data-item" datatype="research">
                    <div className="internal mover2">Competitive analysis</div>
                  </div>
                  <div className="services-data-item" datatype="research">
                    <div className="internal mover3">Usability testing</div>
                  </div>
                  <div className="services-data-item" datatype="research">
                    <div className="internal mover4">User interviews</div>
                  </div>
                </div>
              )}

              {selectedService === "design" && (
                <div>
                  {/*data 2 */}
                  <div className="services-data-item" datatype="design">
                    <div className="internal mover1">UX/UI Design</div>
                  </div>
                  <div className="services-data-item" datatype="design">
                    <div className="internal mover2">Wireframing / Prototyping</div>
                  </div>
                  <div className="services-data-item" datatype="design">
                  <div className="internal mover3">Interaction design </div>
                  </div>
                  <div className="services-data-item" datatype="design">
                    <div className="internal mover4">Brand Design </div>
                  </div>
                </div>
              )}

              {selectedService === "development" && (
                <div>
                  {/*data 3 */}
                  <div className="services-data-item" datatype="development">
                    <div className="internal mover1">HTML/CSS/JS</div>
                  </div>
                  <div className="services-data-item" datatype="development">
                  <div className="internal mover2">React.Js/Svelte</div>
                  </div>
                  <div className="services-data-item" datatype="development">
                  <div className="internal mover3">Headless CMS</div>
                  </div>
                  <div className="services-data-item" datatype="development">
                  <div className="internal mover4">Headless Ecommerce</div>
                  </div>
                </div>
              )}
            </div>
            <NavLink to="/Work" className="services-cta">
              <img src={ionarrow} alt="small arrow"></img>
              <div>See all projects</div>
              </NavLink>
          </div>
          
          
        </div>
      </div>

    </div>
  );
};

export default Home;