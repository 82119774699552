import React, { useState, useEffect } from "react";
import NavBar from "./Navbar";
import { NavLink } from "react-router-dom";

// imgs
import isacmob from "../images/isacmoblogosmall2.svg";

const Header = () => {
  const [didScroll, setDidScroll] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const delta = 5;
  const [navbarHeight, setNavbarHeight] = useState(0);

  useEffect(() => {
    setNavbarHeight(document.querySelector('header').offsetHeight);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setDidScroll(true);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    function hasScrolled() {
      const st = window.scrollY || window.pageYOffset;

      if (Math.abs(lastScrollTop - st) <= delta) {
        return;
      }

      if (st > lastScrollTop && st > navbarHeight) {
        document.querySelector('header').classList.remove('nav-down');
        document.querySelector('header').classList.add('nav-up');
      } else {
        if (st + window.innerHeight < document.documentElement.scrollHeight) {
          document.querySelector('header').classList.remove('nav-up');
          document.querySelector('header').classList.add('nav-down');
        }
      }

      setLastScrollTop(st);
    }

    if (didScroll) {
      hasScrolled();
      setDidScroll(false);
    }
  }, [didScroll, lastScrollTop, delta, navbarHeight]);

  return (
    <header>
      <div className="Container topheadwid">
        <NavLink to="/" className="isacmoblogo">
          <img src={isacmob} className="desklogo" alt="cool isac logo" />
          <img src={isacmob} className="moblogo" alt="cool isac logo" />
        </NavLink>
        <NavBar />
      </div>
    </header>
  );
};

export default Header;
