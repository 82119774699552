import React, { useEffect } from 'react';
import Lenis from '@studio-freight/lenis';
import { useLocation } from 'react-router-dom';

const SmoothScroll = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const lenis = new Lenis({
      smoothWheel: true,
      duration: 0.9,
    });

    lenis.on('scroll', () => {
      // console.log(e)
    });

    const raf = (time) => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    requestAnimationFrame(raf);

    // Scroll to top whenever pathname changes
    window.scrollTo(0, 0); // Directly setting the scroll position

    // Clean up the event listener when the component unmounts
    return () => {
      lenis.off('scroll');
    };
  }, [pathname]); // Trigger on pathname change

  return <div></div>;
};

export default SmoothScroll;