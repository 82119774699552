import React from "react";
import { NavLink } from "react-router-dom";

import opensvg from "../images/opensvg.png";

const Work = () => {
    return (
      <div className="Work">

          <div className="Container-column ab-b work-page">
          <div className="Work-title ptop">Work <br></br>experience</div>

          <div className="threerow">
            <div className="ab-exp">
              <div className="exp-num e-border">03</div>
              
              <div className="exp-tags e-border">
                <div className="exp-tag exp-des">Designer</div>
                <div className="exp-tag exp-dev">Developer</div>
                <div className="exp-tag exp-co">Co-founder</div>
              </div>
              
              <NavLink to="/Laghmari" className="exp-title e-border">
                  Laghmari <img src={opensvg} alt="small window"></img>
                </NavLink>
              
              <div className="exp-date e-border">2023</div>
              
              <div className="exp-place e-border">Gothenburg / Stockholm</div>
              
              <div className="exp-url e-border">https://www.laghmari.com</div>
              
              <div className="exp-bread">Laghmari, a sustainability-focused clothing brand. I've contributed across garment design, marketing, web design, and Ecommerce development. Involved in every step, I've embraced a multidisciplinary approach to redefine fashion with a positive impact.</div>
            </div>

            <div className="ab-exp">
            <div className="exp-num e-border">02</div>
              
              <div className="exp-tags e-border">
                <div className="exp-tag exp-des">UX Designer</div>
                <div className="exp-tag exp-int">Internship</div>
              </div>
              
              <NavLink to="/OneStepBeyond" className="exp-title e-border">OneStepBeyond <img alt="small window" src={opensvg}></img></NavLink>
              
              <div className="exp-date e-border">2023</div>
              
              <div className="exp-place e-border">Gothenburg / Borås</div>
              
              <div className="exp-bread">Internship at a fast-paced strategic marketing agency as a UX Designer working with a vast variety of clients.</div>
            </div>

            <div className="ab-exp">
            <div className="exp-num e-border">01</div>
              
              <div className="exp-tags e-border">
                <div className="exp-tag exp-des">UX Designer</div>
                <div className="exp-tag exp-int">Internship</div>
              </div>
              
              <NavLink to="/Kryptoskatt" className="exp-title e-border">Kryptoskatt <img alt="small window" src={opensvg}></img></NavLink>
              
              <div className="exp-date e-border">2022</div>
              
              <div className="exp-place e-border">Gothenburg / India</div>
              
              <div className="exp-bread">Internship at a fintech startup that developed a financial services platform for users to import and manage their digital assets and cryptocurrencies.</div>
            </div>
          </div>
          
          <div className="more-projects">
              <div className="Work-title workpaddingtop2">
                Other projects
              </div>
              <div className="project-item pitop">
                <p className="projectsection pstitle">Skbnvg.se</p>
                <div className="projectsection">Headless CMS setup for Content Hub</div>
                <div className="projectsection">Design / Fullstack Development</div>
              </div>
              <div className="project-item">
                <p className="projectsection pstitle">Chaosperfumes.com</p>
                <div className="projectsection">Shopify storefront for perfume startup</div>
                <div className="projectsection">Design / Shopify Store</div>
              </div>
              <div className="project-item">
                <p className="projectsection pstitle">Ompa Ompa</p>
                <div className="projectsection">Drum machine web app</div>
                <div className="projectsection">Design / Web Development</div>
              </div>
              <div className="project-item">
                <p className="projectsection pstitle">The Beacon Report</p>
                <div className="projectsection">News website</div>
                <div className="projectsection">Design / Web Development</div>
              </div>
            </div>
        </div>

      </div>
    );
  };
  
  export default Work;