import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import osbcase from "../images/osbcase.png";

import doublediamond from "../images/doublediamond.png";
import discovery from "../images/discoverystage.png";
import definition from "../images/definitionstage.png";
import ideation from "../images/ideationstage.png";
import delivery from "../images/deliverystage.png";

import initialtesting from "../images/initialtesting.png";
import listofdata from "../images/listofdata.png";
import quant from "../images/quant.png";
import qual from "../images/qual.png";
import funsummary from "../images/funsummary.png";
import informed from "../images/informed.png";
import presentation from "../images/presentation.png";
import indepth from "../images/indepth.png";
import hangers from "../images/hangers.png";

import success from "../images/success.svg";
import wrench from "../images/wrenchsvg.svg";
import cogsvg from "../images/cogsvg.svg";

const OneStepBeyond = () => {

  useEffect(() => {
    const handleImageLoad = (event) => {
      event.target.parentNode.classList.add("loaded");
    };

    const blurDivs = document.querySelectorAll(".blur-load");

    blurDivs.forEach(div => {
      const img = div.querySelector("img");
      if (img.complete) {
        div.classList.add("loaded");
      } else {
        img.addEventListener("load", handleImageLoad);
      }
    });

    return () => {
      blurDivs.forEach(div => {
        const img = div.querySelector("img");
        img.removeEventListener("load", handleImageLoad);
      });
    };
  }, []);

  return (
    <div className="casestudypage">

      <div className="Container-column">
        <div>

          {/* Hero */}
          <div className="case-section">
            <div className="case-oversight heroborder">Internship <br></br> @ OneStepBeyond</div>
            <div className="heroborder">
              <div className="case-header">Casestudy: Usability testing for a client in conveyor tech</div>
            </div>

            <div className="case-lateral heroborder">
              <div className="blur-load hangerdiv">
                <img className="hed-hanger loadedbild" src={hangers} alt="onestepbeyond"></img>
              </div>
              <div className="blur-load osbdiv">
                <img className="header-img-osb loadedbild" src={osbcase} alt="onestepbeyond"></img>
              </div>

            </div>
          </div>

          {/* Content */}
          <div className="case-section">

            <div className="case-lateral">
              <div className="case-basics">
                Overview
              </div>
              <div className="osb-overviewtext">
                Join me on a journey through my 4-month internship at OneStepBeyond, a strategic marketing agency in Gothenburg/Borås in 2023. This case study explores a usability testing I did for one of our clients in the conveyor tech industry.
              </div>
            </div>
            <div className="case-lateral">
              <div className="case-basics">
                Role
              </div>
              <div className="osb-overviewtext">
                At OneStepBeyond, my role as an intern UX Designer allowed me to engage in diverse tasks, collaborating with various team members. I assisted art directors with restaurant clients, supported developer colleagues with WordPress and Webflow builds, and worked closely with the digital designer from designing new UI features to websites from scratch.
              </div>
            </div>
          </div>

          <div className="case-section">
            <div className="casestudy-section-title">Business goals</div>
            <div className="case-lateral">
              <div className="goal">
                <div className="number-indicator">1.</div>
                <div className="casestudy-undertitle csunderlg">Evaluate the existing product</div>
                <div className="casestudy-bread">
                  Find a way to evaluate the existing product and user experience and gather an understanding of the specialized needs of the niche Conveyor industry user group, despite limited time and resources.
                </div>
              </div>

              <div className="goal">
                <div className="number-indicator">2.</div>
                <div className="casestudy-undertitle csunderlg">Underline areas of opportunity</div>
                <div className="casestudy-bread">
                  After identifying the issues, provide suggested courses of action based on the insights generated from the evaluation.
                </div>
              </div>

              <div className="goal">
                <div className="number-indicator">3.</div>
                <div className="casestudy-undertitle csunderlg">Improve upon the user experience</div>
                <div className="casestudy-bread">
                  Bring fourth new design solutions and ideas to solve underlined problems and/or improve the overall user experience based on data
                </div>
              </div>
            </div>

          </div>

          <div className="case-section">
            <div className="case-lateral box-x-axis">
              <div className="case-box">
                <div className="casestudy-section-title">Initial evaluation</div>
                <div className="casestudy-bread">
                  With my perspective of a UX Designer and through the a set principles of UX guru Bruce Tognazzini, I embarked on an initial evaluation. This involved mapping out the existing sitemap and  identifying apparent UI issues.
                </div>
                <div className="casestudy-bread">
                  In light of this, I decided to conduct Observational Usability Testing. This marked the transition from intuitive evaluation to empirical exploration, enabling me to substantiate my findings and recommendations with concrete user data.
                </div>
              </div>

              <img className="case-header-img" src={initialtesting} alt="chad"></img>

            </div>
          </div>

          <div className="case-section">
            <div className="case-lateral box-x-axis">

              <div className="case-box">

                <div className="casestudy-section-title">Transition to Testing Methodology:</div>
                <div className="casestudy-bread">
                  Recognizing the limitations of my initial evaluation due to a lack of industry and target user understanding, I needed data-driven insights to inform improvements.
                </div>
                <div className="casestudy-bread">
                  To achieve this, I adopted the Double Diamond design process framework. This allowed me to progress from evaluation to usability testing, where the focus shifted to empirical exploration.
                </div>

              </div>

              <div className="case-box">
                <img className="case-header-img" src={doublediamond} alt="chad"></img>
              </div>

            </div>
          </div>

          <div className="case-section">
            <div className="case-lateral box-x-axis">

              <div className="case-box">
                <div className="casestudy-section-title">01. Discovery stage</div>
                <div className="casestudy-bread">
                  During the discovery stage, my objective was to gain valuable insights into the user experience through observational usability tests. These tests were designed to measure the site's effectiveness in facilitating users' needs and goals, with a focus on assessing how the site drives the user, how well it helps the user convert and how satisfying it is to use.
                </div>

              </div>

              <img className="case-header-img" src={discovery} alt="chad"></img>

            </div>
          </div>

          <div className="case-section">
            <div className="casestudy-section-title">Test structure:</div>
            <div className="case-lateral">
              <div className="goal">
                <div className="casestudy-undertitle csunderlg heroborder">Before</div>
                <div className="casestudy-bread testareas">
                  <div>Hypothesis</div>
                  <div>Defining user goals</div>
                  <div>Development of tasks.</div>
                  <div>Design task descriptions</div>
                </div>
              </div>
              <div className="goal">
                <div className="casestudy-undertitle csunderlg heroborder">During</div>
                <div className="casestudy-bread testareas">
                  <div>Started meeting</div>
                  <div>Task descriptions</div>
                  <div>Moderator checklist</div>
                  <div>Consent for documentation</div>
                  <div>Walk-through of Scenarios</div>
                  <div>Deep-dive interview</div>
                  <div>SEQ Survey after every task</div>
                </div>
              </div>
              <div className="goal">
                <div className="casestudy-undertitle csunderlg heroborder">After</div>
                <div className="casestudy-undertitle testareas">
                  Onto the Definition stage!
                </div>
              </div>

            </div>
          </div>

          <div className="case-section">
            <div className="case-lateral box-x-axis">
              <div className="case-box">
                <div className="casestudy-section-title">Compiling and <br></br>Analyzing the Data</div>
                <div className="casestudy-bread">
                  After the completion of the usability tests, I reviewed the test recordings and organized the data into a structured format. This involved documenting the scenarios, observed results, user opinions, interactions, encountered problems and metrics such as time, clicks, SEQ (System Usability Scale), and success rates.
                </div>
              </div>
              <div className="case-box-img-smaller">
                <img className="case-header-img" src={listofdata} alt="chad"></img>
              </div>

            </div>

          </div>

          <div className="case-section">

            <div className="casestudy-section-title">
              Test Findings
            </div>

            <div className="casestudy-bread test-findings-wid">
              Then, I extracted quantitative and qualitative findings into to separate lists, which I then could examine and identify problems, pain points or issues that either occurred for the majority of users or had substantial impact on the users experience.
            </div>
            <div className="case-lateral box-x-axis">
              <div className="case-box">
                <div className="havebeforebox">
                  <img className="" src={quant} alt="chad"></img>
                </div>
                <div className="casestudy-basics">
                  Extensive list of compiled and analyzed of Quantitative test data
                </div>


              </div>

              <div className="case-box">
                <div className="havebeforebox">
                  <img className="" src={qual} alt="chad"></img>
                </div>
                <div className="casestudy-basics">
                  Extensive list of compiled and analyzed of Qualitative test data
                </div>

              </div>

            </div>

          </div>


          <div className="case-section">
            <div className="case-lateral box-x-axis">

              <div className="case-box">
                <div className="casestudy-section-title">02. Definition stage</div>
                <div className="casestudy-bread">
                  After having conducted the tests, compiling the data and findings, I came to the conclusion that the website has the following main problems:
                </div>
              </div>
              <img className="case-header-img" src={definition} alt="chad"></img>

            </div>
          </div>

          <div className="case-section">
            <div className="case-lateral box-x-axis">

              <div className="case-box">
                <div className="casestudy-section-title">The main problems</div>
                <div className="casestudy-undertitle csunderlg">01. Call to action</div>
                <div className="casestudy-undertitle csunderlg">02. Information Architecture</div>
                <div className="casestudy-undertitle csunderlg">03. Accessibility</div>
                <div className="casestudy-bread">
                  I summarized the existing problems with the website into three categories. In order to keep the information easily digestible I aimed to design fun and engaging infographics to make the problems better understood and evoke empathy.
                </div>
              </div>

              <div className="case-box-img-smaller">
                <img className="case-header-img" src={funsummary} alt="chad"></img>
              </div>
            </div>
          </div>

          <div className="case-section">
            <div className="case-lateral box-x-axis">

              <div className="case-box">

                <div className="casestudy-section-title">In-depth casestudy for each problem area:</div>
                <div className="casestudy-bread ">
                  However, I also felt the need to further explain how I came to the conclusions I did, so I wrote three casestudies where I explained My process and how the website was not adequate in holding up to the users needs and goals with examples and thought processes within the three categories.
                </div>
              </div>
              <div className="case-box-img-smaller">
                <img className="case-header-img" src={indepth} alt="chad"></img>
              </div>
            </div>
          </div>


          <div className="case-section">

            <div className="case-lateral box-x-axis">

              <div className="case-box">
                <div className="casestudy-section-title">03. Ideation stage</div>
                <div className="casestudy-bread">
                  Now after having established what needed to be improved, I started exploring different solutions to solve the problems and/or improve the user experience of the website.
                </div>
              </div>
              <div className="case-box">
                <img className="case-header-img" src={ideation} alt="chad"></img>
              </div>

            </div>
          </div>

          <div className="case-section">
            <div className="case-lateral box-x-axis">

              <div className="case-box">
                <div className="casestudy-section-title">Designing data-driven solutions to adress found problems</div>
                <div className="casestudy-bread">
                  For instance, one critical problem occurred during the first task, where the users was asked to find a specific piece of information. And a simple way to find that information was to use the first available links on the landingpage, whereupon 50% of the users were unsuccessful in finding the destination they where looking for and instead went spent time looking for the destination through other means, and 50% of the users accidentally clicked the wrong link.
                </div>
                <div className="casestudy-bread">
                  To solve this problem, I increased the space between the elements, added better titles, link texts and made use of the already existing colors to indicate the different areas.
                </div>
              </div>

              <div className="case-box-img-smaller">
                <img className="case-header-img" src={informed} alt="chad"></img>
              </div>
            </div>
          </div>


          <div className="case-section">
            <div className="case-lateral box-x-axis">

              <div className="case-box">
                <div className="casestudy-section-title">04. Delivery stage</div>
                <div className="casestudy-bread">
                  Finally for the final phase in the process I delivered the results and insights through a presentation:
                </div>
              </div>
              <img className="case-header-img" src={delivery} alt="chad"></img>
            </div>

          </div>

          <div className="case-section">
            <div className="case-lateral box-x-axis">

              <div className="case-box">
                <div className="casestudy-section-title">Presenting test results and sending out report</div>
                <div className="casestudy-bread">
                  Finally, in the final week of my internship I held a presentation of the usability test results. I also dispatched a report that consisted of three detailed case studies for each of the identified problem areas. These case studies included a comprehensive exploration of users' goals and needs, a step-by-step walk-through of the identified issues, and a showcase of impactful data to underscore the magnitude of each challenge. Followed by proposed solutions with design examples and groundwork for actionable insights that aimed to elevate the user experience and/or solve underlined problems.
                </div>
              </div>
              <img className="case-header-img" src={presentation} alt="chad"></img>
            </div>
          </div>

          {/* Outcome!! */}
          <div className="case-section">
            <div className="casestudy-section-title">How did it go and <br></br> what was the outcome?</div>
            <div className="case-lateral">
              <div className="goal">
                <div className="outcome-box">
                  <img src={cogsvg} alt="chad"></img>
                </div>
                <div className="casestudy-bread">
                  Collaborated with my team and colleagues in a variety of different client tasks and projects.
                </div>
              </div>

              <div className="goal">
                <div className="outcome-box">
                  <img src={wrench} alt="chad"></img>
                </div>
                <div className="casestudy-bread">
                  Commenced the usability testing within a very complex industry, despite having very limited access to users, all within budget constraints.
                </div>
              </div>

              <div className="goal">
                <div className="outcome-box">
                  <img src={success} alt="chad"></img>
                </div>
                <div className="casestudy-bread">
                  Analyzed the test results and successfully made interesting discoveries. Held a presentation of found problems and actionable next-steps to peers and leadership!
                </div>
              </div>
            </div>
          </div>

          <div className="case-section">
            <div className="casestudy-section-title">Key learnings</div>

            <div className="case-lateral">
              <div className="case-box">
                <div className="casestudy-undertitle">01.</div>
                <div className="casestudy-bread">
                  Being a perfectionist in an internship position for only 4 months where you want to learn and do a lot of things was incredibly stressful, but an incredible learning experience and gave me a new outlook on how I should approuch my work process.
                </div>
              </div>

              <div className="case-box">
                <div className="casestudy-undertitle">02.</div>
                <div className="casestudy-bread">
                  Perfection is boring and keeps you from being done. The point of being done is not to finish but to get other things done. This shift in perspective has not only fostered efficiency but has also enriched my understanding of the dynamic and iterative nature of meaningful work.
                </div>
              </div>

            </div>
          </div>

          <div className="case-section">
            <div className="casestudy-section-title">Thank you :) !</div>
            <div className="casestudy-bread case-box">
              Thank you for reading, hope you found it as interesting as I did. Check out more of my projects or reach out, I'd love to hear from you.
            </div>
            <NavLink className="casestudy-cta" to="/Work">See all projects</NavLink>
            <NavLink className="casestudy-cta ptop" to="/Socials">Social links</NavLink>
          </div>

        </div>
      </div>
    </div>
  );
};

export default OneStepBeyond;