import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';

// Views
import Home from "./views/Home";
import About from "./views/About";
import Socials from "./views/Socials";
import Work from "./views/Work";

// studies
import OneStepBeyond from './studies/OneStepBeyond';
import Laghmari from './studies/Laghmari';
import Kryptoskatt from './studies/Kryptoskatt';

// Components
import Header from "./components/Header";
import SmoothScroll from './components/Smoothscroll';
import Footer from './components/Footer';


function App() {
  return (
    <BrowserRouter>

      {/* Header Area */}
      <Header />

      {/* Components */}
      <SmoothScroll />


      {/* Route Area */}
      <Routes>
        <Route element={<Home />} exact path="/" />
        <Route element={<Work />} path="/Work" />
        <Route element={<About />} path="/About" />
        <Route element={<Socials />} path="/Socials" />
        <Route element={<OneStepBeyond />} path="/OneStepBeyond" />
        <Route element={<Laghmari />} path="/Laghmari"></Route>
        <Route element={<Kryptoskatt />} path="/Kryptoskatt"></Route>
      </Routes>

      {/* Footer area */}
      <Footer />
    </BrowserRouter>
  );
}

export default App;
