import React from "react";

const Socials = () => {
    return (
      <div className="Socials">
        
        <div className="socialslinks">
          <div className="socialwrapper ">
            <a className="socialup mover1 button-effect" href="https://www.linkedin.com/in/isacolander/">LINKEDIN</a>
          </div>
          <div className="socialwrapper ">
            <a className="socialup mover2 button-effect" href="mailto:mail@isacolanderux.com">EMAIL</a>
          </div>
          <div className="socialwrapper ">
            <a className="socialup mover3 button-effect" href="https://gv.com/">INSTAGRAM</a>
          </div>
          <div className="socialwrapper ">
            <a className="socialup mover4 button-effect" href="https://github.com/isacolander">GITHUB</a>
          </div>
        </div>

        
        
        </div>
    );
  };
  
  export default Socials;