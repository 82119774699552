import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  useEffect(() => {
    const hamburgerButton = document.querySelector('.hamburger');
    const sidemenulinks = document.querySelectorAll('.sidemenulinks');

    const handleClick = () => {
      const sideMenu = document.querySelector('.side-menu');
      const bunt = document.querySelector('.bun-top');
      const bunm = document.querySelector('.bun-mid');
      const bunb = document.querySelector('.bun-bot');

      sideMenu.style.display = 'flex'; // Display the side menu before toggling the class
      console.log("clicked");

      bunt.classList.toggle("whitebun");
      bunm.classList.toggle("whitebun");
      bunb.classList.toggle("whitebun");


      // Delay the addition of the 'visible' class by a short timeout
      setTimeout(() => {
        sideMenu.classList.toggle('visible');
      }, 2);
    };

    hamburgerButton.addEventListener('click', handleClick);

    const menuCloser = () => {
      const sideMenu = document.querySelector('.side-menu');
      console.log("gege");
      const bunt = document.querySelector('.bun-top');
      const bunm = document.querySelector('.bun-mid');
      const bunb = document.querySelector('.bun-bot');

      sideMenu.style.display = 'flex'; // Display the side menu before toggling the class

      bunt.classList.toggle("whitebun");
      bunm.classList.toggle("whitebun");
      bunb.classList.toggle("whitebun");

      setTimeout(() => {
        sideMenu.classList.toggle('visible');
      }, 2);
    }

    sidemenulinks.forEach(element => {
      element.addEventListener('click', menuCloser);
    });


    return () => {
      // Clean up the event listener when the component is unmounted
      hamburgerButton.removeEventListener('click', handleClick);
      sidemenulinks.forEach(element => {
        element.removeEventListener('click', menuCloser);
      });
    };
  }, []);

    return (
      <nav>
        <NavLink className="Navlink button-effect" to="/Work" > WORK </NavLink>
        <NavLink className="Navlink button-effect" to="/About"> ABOUT </NavLink>
        <NavLink className="Navlink button-effect" to="/Socials"> SOCIALS </NavLink>

        <section className="hamburger">
            <div className="bun-top"></div>
            <div className="bun-mid"></div>
            <div className="bun-bot"></div>
          </section>

        <div className="side-menu">
          <div className="sidemenucontainer">
          <NavLink className="sidemenulinks" to="/"> Home </NavLink>
          <NavLink className="sidemenulinks" to="/Work"> Work </NavLink>
          <NavLink className="sidemenulinks" to="/About"> About </NavLink>
          <NavLink className="sidemenulinks" to="/Socials"> Socials </NavLink>
          </div>
        </div>
      </nav>
    );
  };
  
  export default Navbar;