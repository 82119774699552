import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

import lghm from "../images/lghmvan.png";
import lghmocean from "../images/lghmoffice.png";
import prerelease from "../images/prerelease.png";
import headlessecom from "../images/headlessecom.png";
import horizontallghm from "../images/horizontal-lghm.svg";

import brandelements2 from "../images/brandelements2.png";
import brandelements3 from "../images/brandelements3.png";
import brandelements4 from "../images/brandelements4.png";



const Laghmari = () => {

    useEffect(() => {
        const handleImageLoad = (event) => {
            event.target.parentNode.classList.add("loaded");
        };

        const blurDivs = document.querySelectorAll(".blur-load");

        blurDivs.forEach(div => {
            const img = div.querySelector("img");
            if (img.complete) {
                div.classList.add("loaded");
            } else {
                img.addEventListener("load", handleImageLoad);
            }
        });

        return () => {
            blurDivs.forEach(div => {
                const img = div.querySelector("img");
                img.removeEventListener("load", handleImageLoad);
            });
        };
    }, []);

    return (
        <div className="casestudypage">
            <div className="Container-column laghmari-body">

                <div className="lghm-lateral">

                    <div className="case-section">
                        <div className="case-lateral lghmgap">
                            <div className="lghm-box lghmvanurl blur-load">
                                <img src={lghm} className="lghm-header-bild loadedbild" alt="laghmari team"></img>
                            </div>
                            <div className="blur-load lghm-box lghmoffice">
                                <img src={lghmocean} className="lghm-header-bild loadedbild" alt="laghmari team"></img>
                            </div>
                        </div>

                        <div className="case-lateral lghmgap desk-lghm-landing">
                            <div className="lghm-box-lower">
                                <div className="datesrow1">
                                    <div className="first-step">01</div>
                                    <div className="second-step">02</div>
                                </div>

                                <div className="mobile-lghm-prelude">
                                    0% Fast-fashion, <br></br>
                                    100% Sustainability.
                                </div>
                            </div>
                            <div className="lghm-box-lower darkgreen">
                                <div>
                                    <div className="third-step">03</div>
                                </div>
                                <div>
                                    <div className="Laghmari-titel">
                                        LAGHMARI
                                    </div>

                                    <div className="titel-lateral">
                                        <div className="titel-s">est.</div>
                                        <div className="titel-b">2023</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mobile-lghm-box">
                            <div className="Laghmari-titel">
                                LAGHMARI
                            </div>

                            <div className="titel-lateral">
                                <div className="titel-s">est.</div>
                                <div className="titel-b">2023</div>
                            </div>

                            <div className="prelude">
                                <div className="mobile-lghm-prelude">
                                    0% Fast-fashion, <br></br>
                                    100% Sustainability
                                </div>
                                <div className="lghm-prelude">
                                    A clothing brand for the future, with a 100% focus on sustainability and 0% Fast-fashion.
                                </div>
                            </div>

                        </div>
                    </div>



                    {/* Overview and role */}
                    <div className="case-section">

                        <div className="biglogo">
                            <img src={horizontallghm} alt="horizontal logotype"></img>
                        </div>

                        <div className="case-lateral">

                            <div className="lghm-content-box">
                                <div className="lghms-basics">Overview</div>
                                <div className="lghm-bread-text">
                                    Laghmari is not just a clothing brand; it's a testament to a vision — a vision of sustainable fashion and responsible design. With a focus on 0% Fast-fashion and 100% Sustainability, Laghmari aims to redefine the fashion landscape. Rooted in the values of eco-consciousness and ethical design, the brand signifies a commitment to creating a positive impact.
                                </div>
                            </div>
                            <div className="lghm-content-box">
                                <div className="lghms-basics">
                                    Role
                                </div>
                                <div className="lghm-bread-text">
                                    My journey with Laghmari has been a hands-on immersion, transforming the brand's visions into reality. Collaborating with my colleagues, I've played a pivotal role in manifesting the brand's ethos by contributing to sustainable garment designs, brand identity, and crafting our digital presence. Together, we're not just redefining fashion but setting a precedent for a more sustainable and conscious future.
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Prerelease */}
                    <div className="case-section">
                        <div className="case-lateral-lghm-lg">
                            <div className="lghm-content-box">
                                <img src={prerelease} className="lghm-UI-bild" alt="laghmari team"></img>
                            </div>
                            <div className="lghm-content-box">
                                <div className="lghm-tag exp-devdev">
                                    In development
                                </div>
                                <div className="casestudy-section-title pbfixed">
                                    Design and development of prerelease website
                                </div>
                                <div className="casestudy-bread">
                                    My current focus involves the development of a new prerelease website. This platform will serve as the gateway to the brand's upcoming collection, offering a sneak peek at the initial samples and designs. As a crucial step in Laghmari's early journey, this website aims to not just showcase but embody the essence of sustainability and responsible fashion, setting the tone for the brand's vision.
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Headless Ecom */}
                    <div className="case-section">
                        <div className="case-lateral-lghm-lg">
                            <div className="lghm-content-box">
                                <img src={headlessecom} className="lghm-UI-bild" alt="laghmari team"></img>
                            </div>
                            <div className="lghm-content-box">
                                <div className="lghm-tag exp-devdev">
                                    In development
                                </div>
                                <div className="casestudy-section-title pbfixed">
                                    Headless Ecommerce Experience
                                </div>

                                <div className="casestudy-bread">
                                    Engaged in the creation of a unique headless ecommerce solution, I'm super excited to lead the design and development utilizing the Shopify Hydrogen techstack alongside Next.js. Leveraging a deep-rooted passion for ecommerce, the focus is to craft a customized platform that resonates with the brand's sustainability principles with the goal of delivering a seamless and interactive interface, with deep focus on the customer experience.
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="case-section">

                        <div className="colorsgggg">

                            <div className="maincolor">
                                <div className="lghm-colors-padding">
                                    Brand colors
                                </div>
                                <div className="color-code-dark">
                                    #72876A
                                </div>
                            </div>
                            <div className="colors-lateral">
                                <div className="othercolor darkblue">
                                    <div className="color-code-white">
                                        #000214
                                    </div>
                                </div>
                                <div></div>
                                <div className="othercolor lightwhite">
                                    <div className="color-code-dark">
                                        #F2FDFF
                                    </div>
                                </div>
                            </div>
                            <div className="colors-lateral">
                                <div className="othercolor darkgreen">
                                    <div className="color-code-white">
                                        #4C5E56
                                    </div>
                                </div>
                                <div className="othercolor-lastsection">
                                    <div className="othercolor lghmyellow">
                                        <div className="color-code-dark">
                                            #F4D06F
                                        </div>
                                    </div>
                                    <div className="othercolor warningorange">
                                        <div className="color-code-dark">
                                            #FF8811
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="case-section">

                        <div className="case-lateral">
                            <div className="promo-left services-title">
                                (Promo)
                            </div>

                            <div className="promo-right">
                                <div className="desk-cool-bild">
                                    <img src={brandelements4} className="lghmpromoimg" alt="lghm promo"></img>
                                </div>

                                <div className="case-lateral lghmgap">
                                    <div className="case-box">
                                        <img src={brandelements2} className="cool-lghmbild" alt="lghm promo"></img>
                                    </div>
                                    <div className="case-box">
                                        <img src={brandelements3} className="cool-lghmbild" alt="lghm promo"></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="case-section">
                        <div className="casestudy-section-title">Thank you :) !</div>
                        <div className="casestudy-bread case-box">
                            Thank you for exploring this journey and the collaborative work undertaken! Please stay updated with our progress and future developments or feel free to reach out for feedback, collaborations, or to discuss similar projects.
                        </div>
                        <NavLink className="casestudy-cta" to="/Work">See all projects</NavLink>
                        <NavLink className="casestudy-cta ptop" to="/Socials">Social links</NavLink>
                    </div>


                </div>



            </div>


        </div>
    );
};

export default Laghmari;