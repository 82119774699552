import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

// images
import kryptosheader from "../images/kryptos4.png";
import success from "../images/success.svg";
import femfem from "../images/femfem.svg";
import plusfem from "../images/plusfem.svg";

import leanuxprocess from "../images/leanuxprocess.png";
import leandiscover from "../images/leandiscover.png";
import leancreate from "../images/leancreate.png";
import leancheck from "../images/leancheck.png";

import examiningUI from "../images/examiningUI.png";

import havebefore1 from "../images/havebefore1.png";
import havebefore2 from "../images/havebefore2.png";

import branddir1 from "../images/branddir1.png";
import branddir2 from "../images/branddir2.png";
import branddir3 from "../images/branddir3.png";
import branddir4 from "../images/branddir4.png";
import finalbrand from "../images/finalbrand2.png";
import darkmode from "../images/darkmode.png";

const Kryptoskatt = () => {

    useEffect(() => {
        const handleImageLoad = (event) => {
          event.target.parentNode.classList.add("loaded");
        };
    
        const blurDivs = document.querySelectorAll(".blur-load");
    
        blurDivs.forEach(div => {
          const img = div.querySelector("img");
          if (img.complete) {
            div.classList.add("loaded");
          } else {
            img.addEventListener("load", handleImageLoad);
          }
        });
    
        return () => {
          blurDivs.forEach(div => {
            const img = div.querySelector("img");
            img.removeEventListener("load", handleImageLoad);
          });
        };
      }, []); 

    return (
        <div className="casestudypage">
            <div className="Container-column">

                {/* Hero landing */}
                <div className="case-section">

                    <div className="krypto-lateral">
                        <div className="case-box kryptobox">
                            <div className="case-oversight kryptooversight">Internship <br></br> @ OneStepBeyond</div>

                            <div className="case-header krypto-header">Casestudy:<br></br>
                                UX Designing for the launch of Kryptoskatt</div>
                                <div className="scrolltext scrolltext-kryptocase">(Scroll)</div>
                        </div>
                        <div className="case-box">
                            <div className="blur-load case-header-krypto-div">
                                <img className="case-header-krypto loadedbild" alt="UI frames" src={kryptosheader}></img>
                            </div>
                        </div>
                    </div>

                </div>

                {/* overview */}
                <div className="case-section">

                    <div className="case-lateral">
                        <div className="case-basics">
                            Overview
                        </div>
                        <div className="osb-overviewtext">
                            Kryptoskatt is a fintech SaaS startup that develops a product aimed at users with cryptocurrencies and digital assets who are in need of a secure, reliable, and easy-to-use platform for managing their investments. With Kryptoskatt's software, users can easily manage their portfolios, conduct trades, and securely store their digital assets in one centralized location.
                        </div>
                    </div>
                    <div className="case-lateral">
                        <div className="case-basics">
                            Role
                        </div>
                        <div className="osb-overviewtext">
                            As an intern UX Designer at Kryptoskatt, I got the opportunity of working on a variety of different aspects of the product such as, branding, webdesign, web- and mobile app design.
                        </div>
                    </div>
                </div>

                {/* BUsiness goals */}
                <div className="case-section">
                    <div className="casestudy-section-title">Business goals</div>
                    <div className="case-lateral">
                        <div className="goal">
                            <div className="number-indicator nb-dark">1.</div>
                            <div className="casestudy-undertitle csunderlg">
                                Crypto mass adoption = important
                            </div>
                            <div className="casestudy-bread">
                                Focus on ease-to-use functionality and massive integration options in order to work towards the goal of making the world of crypto and digital assets easily accessible to new users.
                            </div>
                        </div>

                        <div className="goal">
                            <div className="number-indicator nb-dark">2.</div>
                            <div className="casestudy-undertitle csunderlg">
                                Stand out from competitors
                            </div>
                            <div className="casestudy-bread">
                                Find ways to stand out from competitors through brand redesign and other areas of opportunity.
                            </div>
                        </div>

                        <div className="goal">
                            <div className="number-indicator nb-dark">3.</div>
                            <div className="casestudy-undertitle csunderlg">
                                Successful release of the alpha version!
                            </div>
                            <div className="casestudy-bread">
                                Make our deadline and release the alpha version Kryptoskatt with updated branding, website and app experiences.
                            </div>
                        </div>
                    </div>
                </div>

                {/* Design process */}
                <div className="case-section">
                    <div className="case-lateral box-x-axis">
                        <div className="case-box">
                            <div className="casestudy-section-title">
                                Design process
                            </div>
                            <div className="casestudy-bread">
                                At Kryptoskatt, a young and dynamic fintech startup, our design process resonated with the principles of Lean UX. In a whirlwind environment where speed was key, we adhered to an iterative approach consisting of three phases, 1. Discover, 2. Create, and 3. Check.
                            </div>
                            <div className="casestudy-bread">
                                With our iterative design process approach we swiftly addressed critical needs to ensure the progression of the company, product development and that deadlines were met. Most important of all was the communication between peers, developers and leadership, enabling us to adapt and pivot when needed.
                            </div>
                        </div>

                        <div className="case-box">
                            <img className="case-header-img" src={leanuxprocess} alt="chad"></img>
                        </div>

                    </div>
                </div>

                {/* Disovery */}
                <div className="case-section">
                    <div className="case-lateral box-x-axis">

                        <div className="case-box">
                            <div className="casestudy-section-title">01. Discovery stage</div>
                            <div className="casestudy-bread">
                                In the discovery stage at Kryptoskatt, my primary focus was immersing myself in the realm of Web3, cryptocurrency, and digital assets. This aimed to enhance my understanding of both the industry and the users the product aimed to serve. I studied competitors, dissecting their approaches, user experiences and strategies to gain comprehensive insights to find ways to achieve our business goals.
                            </div>

                            <div className="casestudy-bread">
                                Additionally, I engaged in hands-on exploration, creating a test account to navigate the app's user flow and thereafter conducted a examination of the existing user interface, underlining critical issues and transforming these observations into actionable suggestions and innovative solutions. Next, I crafted a series of design proposals that were presented to my peers and leaders.
                            </div>

                        </div>
                        <div className="case-box">
                            <img className="case-header-img" src={leandiscover} alt="chad"></img>
                        </div>
                    </div>
                </div>

                {/* Examining UI */}
                <div className="case-section">
                    <div className="case-lateral box-x-axis">

                        <div className="case-box">
                            <div className="casestudy-section-title">Examining and underlining UI issues</div>
                            <div className="casestudy-bread">
                                The following is an example of my deep-dive into the existing product design and how I documented issues and possible improvements of the UI. This showcases how the side menu items weren’t scoring good enough on WCAG and could be more engaging to use.
                            </div>

                            <div className="casestudy-bread">
                                I also made the argument that if a user spends time on the app, introducing colors for the different menu items will allow the user to register and remember more quickly where to press for their wanted destination.
                            </div>

                        </div>
                        <div className="case-box-img-smaller havebeforebg">
                            <img className="" src={examiningUI} alt="chad"></img>
                        </div>
                    </div>
                </div>

                {/* Creation stage */}

                <div className="case-section">
                    <div className="case-lateral box-x-axis">

                        <div className="case-box">
                            <div className="casestudy-section-title">02. Creation stage</div>
                            <div className="casestudy-bread">
                                In the create stage, my primary focus was designing new solutions to solve previously identified needs and/or issues in the discovery phase. Or I was urgently assigned design tasks to solve issues that had a critical impact on the user experience or functionality.
                            </div>

                        </div>
                        <div className="case-box">
                            <img className="case-header-img" src={leancreate} alt="chad"></img>
                        </div>
                    </div>
                </div>

                {/* What did we have before */}
                <div className="case-section">
                    <div className="case-lateral">
                        <div className="case-box">
                            <div className="casestudy-section-title">What did we have before?</div>
                        </div>

                        <div className="case-box">
                            <div className="casestudy-undertitle">
                                Unpolished UX experience and accessibility issues.
                            </div>
                            <div className="casestudy-bread">
                                In the early days of a startup, functionality comes first. Thus, the user experience had a bunch of room for improvements in UI and accessibility.
                            </div>
                        </div>
                    </div>

                    <div className="case-lateral">
                        <div className="case-box">
                            <div className="havebeforebox">
                                <img className="" src={havebefore1} alt="chad"></img>
                            </div>
                            <div className="casestudy-basics">
                                Uninviting landing page with limited engagement elements, lacking in CTA promotion and user onboarding interactions
                            </div>
                        </div>
                        <div className="case-box">
                            <div className="havebeforebox">
                                <img className="" src={havebefore2} alt="chad"></img>
                            </div>
                            <div className="casestudy-basics">
                                Raw app interface lacking polish, non-compliant with WCAG accessibility standards, and featuring a non-engaging information architecture
                            </div>
                        </div>
                    </div>
                </div>

                {/* Brand direction */}
                <div className="case-section">
                    <div className="case-lateral">
                        <div className="case-box">
                            <div className="casestudy-section-title">Brand direction <br></br>and web design
                            </div>
                        </div>

                        <div className="case-box">
                            <div className="casestudy-undertitle">
                                Exploring design identities and website experiences
                            </div>
                            <div className="casestudy-bread">
                                I was given the task of desinging five new design identities with complete creative freedom. The new designs did not need to be adherrent to the previous directions and had the main focus of innovating whilst being in line with the business goals.
                            </div>
                        </div>
                    </div>

                    <div className="case-lateral hbboxgap">
                        <div className="case-box">
                            <div className="havebeforebox">
                                <img className="case-header-img" src={branddir1} alt="chad"></img>
                            </div>
                            <div className="havebeforebox">
                                <img className="case-header-img" src={branddir2} alt="chad"></img>
                            </div>
                        </div>
                        <div className="case-box">
                            <div className="havebeforebox">
                                <img className="case-header-img" src={branddir3} alt="chad"></img>
                            </div>
                            <div className="havebeforebox">
                                <img className="case-header-img" src={branddir4} alt="chad"></img>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Finilized brand identity */}

                <div className="case-section">
                    <div className="case-lateral box-x-axis">

                        <div className="case-box">
                            <div className="casestudy-section-title">Finilized brand <br></br> identity design</div>
                            <div className="casestudy-bread">
                                After exploring different approaches and identities, my team and I were finally tasked with instead revamping the current one within some specfic parameters. For instance, we wanted to make sure the the brand direction recognized the previous style and values whilst still exploring a new direction that was modern, had greater contrast, WCAG friendly and competitive with the crypto landscape.
                            </div>

                        </div>
                        <div className="case-box">
                            <img className="case-header-img" src={finalbrand} alt="chad"></img>
                        </div>
                    </div>
                </div>

                {/* Redesign UX Darkmode */}
                <div className="case-section">
                    <div className="case-lateral box-x-axis">

                        <div className="case-box">
                            <div className="casestudy-section-title">Redesigning the mobile app UX in darkmode</div>
                            <div className="casestudy-bread">
                                For this task, I was given complete creative freedom and free reigns in redesigning the whole mobile app experience in darkmode. In doing so, my goal was to tackle several key issues, including design cohesion, accessibility, data visualization and the navigation sytem in the newly finalized Kryptoskatt brand.
                            </div>

                        </div>
                        <div className="case-box-img-smaller">
                            <img className="case-header-img" src={darkmode} alt="chad"></img>
                        </div>
                    </div>
                </div>

                {/* Check stage */}
                <div className="case-section">
                    <div className="case-lateral box-x-axis">

                        <div className="case-box">
                            <div className="casestudy-section-title">
                                03. Check stage
                            </div>
                            <div className="casestudy-bread">
                                Finally, the most important part of the design process, deliver the designs to gather insight and feedback from peers, leadership and developers. This was crucial in making sure the designs were within the constraints of the budget and ready to be handed off to development.
                            </div>

                        </div>

                        <img className="case-header-img" src={leancheck} alt="chad"></img>

                    </div>
                </div>

                {/* Outcome!! */}
                <div className="case-section">
                    <div className="casestudy-section-title">How did it go and what was the outcome?</div>
                    <div className="case-lateral">
                        <div className="goal">
                            <div className="outcome-box">
                                <img src={success} alt="chad"></img>
                            </div>
                            <div className="casestudy-bread">
                                Successfully launched the alpha version with the widest range of integrations on the market!
                            </div>
                        </div>

                        <div className="goal">
                            <div className="outcome-box">
                                <img src={plusfem} alt="chad"></img>
                            </div>
                            <div className="casestudy-bread">
                                Kryptoskatt has now over 5 new partnerships with various aggregators, platforms or funders such as Binance Labs, Gate Labs and RocketX.
                            </div>
                        </div>

                        <div className="goal">
                            <div className="outcome-box">
                                <img src={femfem} alt="chad"></img>
                            </div>
                            <div className="casestudy-bread">
                                The Kryptoskatt app has now got its first ever 5-star review on Trustpilot and hopefully has many more reviews to come.
                            </div>
                        </div>
                    </div>
                </div>

                {/* Key learnings */}
                <div className="case-section">
                    <div className="casestudy-section-title">Key learnings</div>

                    <div className="case-lateral">
                        <div className="case-box">
                            <div className="casestudy-undertitle">01.</div>
                            <div className="casestudy-bread">
                                Working at a remote and young startup is definitely a unique experience and one of the main takeaways I bring from the intership is that you can never ask too many questions, communication is key.
                            </div>
                        </div>

                        <div className="case-box">
                            <div className="casestudy-undertitle">02.</div>
                            <div className="casestudy-bread"> 
                                I felt there was great difficulty in having the courage   to speak my mind and have an opinion, especially since it was my first job experience and within a very complex industry. However when I did, great conversations were held and I learned a lot.
                            </div>
                        </div>

                    </div>
                </div>

                {/* Thank you */}
                <div className="case-section">
                    <div className="casestudy-section-title">Thank you :) !</div>
                    <div className="casestudy-bread case-box">
                        Thank you for reading, hope you found it as interesting as I did. Check out more of my projects or reach out, I'd love to hear from you.
                    </div>
                    <NavLink className="casestudy-cta" to="/Work">See all projects</NavLink>
                    <NavLink className="casestudy-cta ptop" to="/Socials">Social links</NavLink>
                </div>


            </div>
        </div>
    );

};

export default Kryptoskatt;